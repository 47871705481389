import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory, matchPath, Route } from 'react-router-dom';
import { IconButton, Toolbar, Typography, Box, Button } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import SettlementsBanner from '../../SettlementsBanner/SettlementsBanner';
import TypePicker from '../../TypePicker/TypePicker';
import Loading from '../loading/Loading';
import { breadcrumbsPropTypes } from '../../../reducers/breadcrumbsReducer';
import routes from '../../../routes/Routes';
import LogoImage from '../../../assets/logoEatClub.svg';
import useStyles from './HeaderStyles';
import RestaurantPicker from '../../restaurantPicker/RestaurantPicker';
import DatePicker from '../../datePicker/DatePicker';
import PlatformPicker from '../../platformPicker/PlatformPicker';
import { canEditAll } from '../../../utils/helpers';
import { userPropTypes } from '../../../data/models/User';

const AppHeader = ({
  breadcrumbs,
  children,
  showDrawer,
  premium = false,
  user,
  restaurantActive,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const ref = useRef(null);
  const history = useHistory();

  const route = routes.find((routeToFind) => matchPath(location.pathname, routeToFind));
  const hideHeader = route?.premiumRequired && !premium;
  const pageTitle = route?.pageTitle || null;
  const customPageTitle = route?.customPageTitle || null;
  const headerBackNavigation = route?.backNavigation;

  const hasBreadcrumbs = breadcrumbs?.data?.length > 0;
  const hasHeaderContent = pageTitle || headerBackNavigation || customPageTitle;

  // Better back functionality  (actually go back, rather than to a hardcoded screen)
  const previousRoute = useRef(route?.path);
  const currentRoute = useRef(route?.path);
  if (currentRoute.current !== route?.path) {
    previousRoute.current = currentRoute.current;
    currentRoute.current = route?.path;
  }

  const onBack = () => {
    // Try going back to the previous screen first
    if (headerBackNavigation?.goBack && previousRoute.current !== currentRoute.current) {
      currentRoute.current = previousRoute.current;
      history.push(currentRoute.current);
      return;
    }

    // If can't go back (e.g. loaded this page directly), use a default back route
    history.push(headerBackNavigation.route);
  };

  return (
    <>
      <Box className={classes.mobileHeader}>
        <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={showDrawer}>
          <MenuIcon />
        </IconButton>
        <LogoImage className={classes.menuLogo} fill='#313131' />
      </Box>

      <Box className={classes.container}>
        <Loading />

        <Box className={classes.containerInner}>
          {!hideHeader && (
            <Box ref={ref} position='static' className={classes.appBar}>
              {/* Payment banner  */}
              {route?.showDebtBanner && <SettlementsBanner />}

              <Toolbar className={classes.headerToolbar}>
                <Box className={classes.header}>
                  {(hasBreadcrumbs || hasHeaderContent) && (
                    <Box style={{ width: '100%' }}>
                      {hasBreadcrumbs && (
                        <Box
                          className={classes.breadcrumbContainer}
                          style={{ marginBottom: '20px' }}
                        >
                          <Breadcrumbs />
                        </Box>
                      )}

                      {hasHeaderContent && (
                        <Box className={classes.titleContainer}>
                          <Box
                            style={{
                              display: 'flex',
                              gap: '24px',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                            }}
                          >
                            {(customPageTitle && customPageTitle()) || (
                              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                {headerBackNavigation && (
                                  <Box
                                    className={classes.headerBackNavigation}
                                    style={{
                                      marginBottom: '20px',
                                      cursor: 'pointer',
                                    }} // Add extra space if we're showing a title too
                                    onClick={onBack}
                                  >
                                    <svg
                                      width='13'
                                      height='12'
                                      viewBox='0 0 13 12'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path d='M6.5 0.5L1 6M1 6L6.5 11.5M1 6H12.5' stroke='black' />
                                    </svg>

                                    <Box className={classes.backNavigationLabel}>
                                      {headerBackNavigation?.label}
                                    </Box>
                                  </Box>
                                )}

                                {/* Title */}
                                {route?.pageIcon && (
                                  <Box style={{ display: 'flex', marginBottom: '12px' }}>
                                    {route?.pageIcon}
                                  </Box>
                                )}

                                <Typography variant='h1' className={classes.title}>
                                  {pageTitle}
                                </Typography>
                              </Box>
                            )}

                            <Box
                              style={{
                                display: 'flex',
                                gap: '8px',
                                flexWrap: 'wrap',
                                height: '40px',
                              }}
                            >
                              <Route exact path={['/offers']}>
                                <Button
                                  color='secondary'
                                  variant='outlined'
                                  onClick={() => {
                                    history.push('/offers/create');
                                  }}
                                  size='large'
                                  disabled={restaurantActive?.closedEarlyToday && !canEditAll(user)}
                                >
                                  Create new
                                </Button>
                              </Route>
                              <Route exact path='*'>
                                <RestaurantPicker />
                              </Route>
                              <Route
                                exact
                                path={['/analytics', '/menus', '/insights', '/transactions']}
                              >
                                <PlatformPicker />
                              </Route>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              gap: '8px',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Route exact path={['/transactions']}>
                              <TypePicker />
                            </Route>
                            <Route exact path={['/analytics', '/transactions', '/insights']}>
                              <DatePicker />
                            </Route>
                            <Route exact path={['/settlements']}>
                              {canEditAll(user) && (
                                <Button
                                  color='secondary'
                                  variant='outlined'
                                  onClick={() => {
                                    history.push('/settlements/historical-statements');
                                  }}
                                >
                                  Historical statements
                                </Button>
                              )}
                              <Button
                                color='secondary'
                                variant='outlined'
                                onClick={() => {
                                  history.push('/support');
                                }}
                              >
                                Need help?
                              </Button>
                            </Route>
                            <Route exact path={['/offers/active']}>
                              <Button
                                color='secondary'
                                variant='outlined'
                                onClick={() => {
                                  history.push('/offers/create');
                                }}
                                size='large'
                                disabled={restaurantActive?.closedEarlyToday && !canEditAll(user)}
                              >
                                Create new
                              </Button>
                            </Route>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Toolbar>
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </>
  );
};

AppHeader.propTypes = {
  premium: PropTypes.bool,
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  showDrawer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  user: userPropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  premium: state.restaurantActive.restaurant?.premium,
  restaurantActive: state.restaurantActive.restaurant,
  breadcrumbs: state.breadcrumbs,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
